<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <button
        class="btn btn-gradient-primary mb-1"
        @click="Submit()"
      >
        {{ $t('saveData') }}
      </button>
      <b-row>
        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('parcelInfo') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-customerCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('customerCode') }} : <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="customer code"
                      rules="required"
                    >
                      <v-select
                        id="h-customerCode"
                        v-model="customer"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        label="username"
                        :options="customerCode"
                        class="w-100 mr-1"
                        @input="getProduct(customer.id)"
                      />
                      <small class="text-danger">{{ errors[0] ? $t('key-295'): '' }}</small>
                      <b-form-checkbox
                        v-model="notproduct"
                        :value="true"
                      >
                        {{ $t('ownerlessGoods') }}
                      </b-form-checkbox>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-parcel-code"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('parcelCode') }} : <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="parcel code"
                      rules="required"
                    >
                      <b-form-input
                        id="h-parcel-code"
                        v-model="parcelCode"
                        :state="errors.length > 0 ? false:null"
                        @input="checkInput"
                      />
                      <small class="text-danger">{{ errors[0] ? $t('key-295'): '' }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-purchase-order"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('key-22') }} :
                    </template>

                    <b-form-input
                      id="h-purchase-order"
                      v-model="purchaseOrder"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-keyword"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('mnemonic') }} :
                    </template>

                    <b-form-input
                      id="h-keyword"
                      v-model="keyword"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-customerCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('transport') }} :
                    </template>

                    <div class="demo-inline-spacing mt-n1">
                      <b-form-radio
                        v-model="transport"
                        name="some-radio9"
                        :value="1"
                        class="custom-control-primary"
                        @change="checkedytpe(transport)"
                      >
                        {{ $t('truck') }}
                      </b-form-radio>
                      <b-form-radio
                        v-model="transport"
                        name="some-radio9"
                        :value="2"
                        class="custom-control-primary"
                        @change="checkedytpe(transport)"
                      >
                        {{ $t('ship') }}
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-productType"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('productType') }} : <span class="text-danger">*</span>
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="product type"
                      rules="required"
                    >
                      <v-select
                        id="h-productType"
                        v-model="productType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        :label="Checkname()"
                        :options="ProductTypeitem"
                        class="w-100 mr-1"
                        @input="reitem()"
                      />
                      <small class="text-danger">{{ errors[0] ? $t('key-82'): '' }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-packbox"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('woodPacking') }} :
                    </template>

                    <b-form-checkbox
                      v-model="packbox"
                      name="check-button"
                      class="mt-50"
                      switch
                      inline
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-checkProduct"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('checkOrder') }} :
                    </template>

                    <b-form-checkbox
                      v-model="checkProduct"
                      name="check-button"
                      class="mt-50"
                      switch
                      inline
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-remark"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('remarks') }} :
                    </template>
                    <b-form-textarea
                      id="h-remark"
                      v-model="remark"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('moneyList') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-checkProduct"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('checkOrder') }} :
                    </template>
                    <b-form-input
                      id="h-checkProduct"
                      v-model="product_inspection_fee"
                      class="w-25"
                    />

                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('repackage') }} :
                    </template>
                    <b-form-input
                      id="h-product-name"
                      v-model="re_wrap_fee"
                      class="w-25"
                    />

                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-trans"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('key-114') }} :
                    </template>
                    <b-form-input
                      id="h-product-trans"
                      v-model="shipping_fee"
                      class="w-25"
                    />

                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-file"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('shippingAttachment') }} :
                    </template>
                    <div
                      class="clrr-setting mt-25 cursor-pointer"
                    >
                      <div class="input-file-container cursor-pointer">
                        <i class="fal fa-plus cursor-pointer mr-50" />{{ $t('key-9') }}
                        <input
                          id="img-file"
                          ref="fileInput"
                          type="file"
                          class="input-file"
                          accept="image/*"
                          multiple
                          @change="handleFileChanges"
                        >
                      </div>
                    </div>

                    <div
                      v-if="imagePreviewUrls.length"
                      class="d-flex"
                    >
                      <div
                        v-for="url in imagePreviewUrls"
                        :key="url"
                        class="preview-image-container mr-25 d-flex"
                      >
                        <img
                          :src="url"
                          alt="Preview"
                          class=""
                        >
                        <div class="preview-image-overlays p-0">
                          <i
                            class="fas fa-times-circle cursor-pointer"
                            @click="removeImage(url)"
                          />
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-priceBox"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('cratePricing') }} :
                    </template>

                    <b-form-input
                      id="h-priceBox"
                      v-model="packaging_fee"
                      class="w-25"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-other"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('other') }} :
                    </template>
                    <b-form-input
                      id="h-product-other"
                      v-model="other"
                      class="w-25"
                    />

                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('size') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('weight') }} :
                    </template>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="basic-weight"
                        v-model="weight"
                        type="number"
                        placeholder="0.00"
                        @input="charge_percheck()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('kilo') }}.
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('widthCm') }} :
                    </template>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="basic-weight"
                        v-model="width"
                        type="number"
                        placeholder="0.00"
                        @input="checkedpricereq()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('cm') }}
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('heightCm') }} :
                    </template>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="basic-weight"
                        v-model="height"
                        type="number"
                        placeholder="0.00"
                        @input="checkedpricereq()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('cm') }}
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('lengthCm') }} :
                    </template>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="basic-weight"
                        v-model="long"
                        type="number"
                        placeholder="0.00"
                        @input="checkedpricereq()"
                      />
                      <b-input-group-append is-text>
                        {{ $t('cm') }}
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-parcel1"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('quantity') }} : <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="h-parcel1"
                      v-model="amount"
                      type="number"
                      class="w-25"
                      @input="checkedamount(amount)"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-parcel1"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >

                    <b-form-checkbox
                      v-model="calPriceAuto"
                      value="true"
                      @input="PriceAuto()"
                    >
                      {{ $t('autoPricing') }}
                    </b-form-checkbox>
                  </b-form-group>

                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-kilo"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ charge_per_save }} :
                    </template>
                    <b-form-input
                      id="h-kilo"
                      v-model="ThinkPerKilo"
                      type="number"
                      class="w-25"
                      :disabled="calPriceAuto"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('date') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="example-received"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('arrivedChinaWarehouse') }} :
                    </template>
                    <b-form-datepicker
                      id="example-received"
                      v-model="come_chinesewarehouse"
                      locale="th"
                      :placeholder="$t('key-283')"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="example-export"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('leftChinaWarehouse') }} :
                    </template>
                    <b-form-datepicker
                      id="example-export"
                      v-model="out_chinesewarehouse"
                      locale="th"
                      :placeholder="$t('key-284')"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="example-arrived-at"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('arrivedThaiWarehouse') }} :
                    </template>
                    <b-form-datepicker
                      id="example-arrived-at"
                      v-model="come_thaiwarehouse"
                      locale="th"
                      :placeholder="$t('key-26')"
                    />
                  </b-form-group>

                  <!-- <b-col md="12">
                    <b-form-group
                      label-for="example-arrived-at"
                      label-cols="3"
          label-cols-lg="3"
          label-cols-md="3"
                    >
                      <template v-slot:label>
                        ถึงไทย :
                      </template>
                      <b-form-datepicker
                        id="example-arrived-thai"
                        v-model="out_thaiwarehouse"
                        locale="th"
                        placeholder="วันที่ถึงไทย"
                      />
                    </b-form-group>
                  </b-col> -->
                </b-col></b-row>
            </div>
          </div>
        </b-col>

      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormCheckbox,
  BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormCheckbox,
    BFormRadio,
  },
  data() {
    return {
      required,
      email,
      product_inspection_fee: 0,
      re_wrap_fee: 0,
      shipping_fee: 0,
      packaging_fee: 0,
      other: 0,
      customer: null,
      customerCode: [],
      parcelCode: null,
      purchaseOrder: null,
      transport: 1,
      keyword: null,
      status: null,
      remark: null,
      amount: 1,
      weight: 0,
      width: 0,
      height: 0,
      long: 0,
      ThinkPerKilo: null,
      calPriceAuto: true,
      parcelExport: null,
      file: null,
      selectedFiles: [],
      uploadedImages: [],
      imagePreviewUrls: [],
      ProductTypeitem: [],
      productType: null,
      charge_per_save: this.$t('calculateByKilo'),
      charge_per_kilo: null,
      charge_per_cue: null,
      charge_per: 1,
      come_chinesewarehouse: null,
      out_chinesewarehouse: null,
      come_thaiwarehouse: null,
      out_thaiwarehouse: null,
      out_chines: null,
      come_chines: null,
      come_thai: null,
      out_thai: null,
      notproduct: false,
      packbox: false,
      checkProduct: false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  async mounted() {
    await this.getUserData()
    this.getProduct()
  },
  methods: {
    checkInput() {
      if (/\s/.test(this.parcelCode) || /\t/.test(this.parcelCode)) {
        this.parcelCode = this.parcelCode.replace(/\s/g, '').replace(/\t/g, '')
      }
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.out_chinesewarehouse) {
            this.out_chines = moment(this.out_chinesewarehouse).format('DD/MM/YYYY')
          }
          if (this.come_chinesewarehouse) {
            this.come_chines = moment(this.come_chinesewarehouse).format('DD/MM/YYYY')
          }
          if (this.come_thaiwarehouse) {
            this.come_thai = moment(this.come_thaiwarehouse).format('DD/MM/YYYY')
          }
          if (this.out_thaiwarehouse) {
            this.out_thai = moment(this.out_thaiwarehouse).format('DD/MM/YYYY')
          }
          if (this.charge_per === 1) {
            this.charge_per_kilo = this.ThinkPerKilo
          }
          if (this.charge_per === 2) {
            this.charge_per_cue = this.ThinkPerKilo
          }
          const obj = {
            uid: this.customer.id,
            username: this.customer.username,
            postnumber: this.parcelCode,
            purchaseOrder: this.purchaseOrder,
            keyword: this.keyword,
            transportType: this.transport,
            product_type: this.productType,
            packbox: this.packbox,
            checkproduct: this.checkProduct,
            remark: this.remark,
            product_inspection_fee: this.product_inspection_fee,
            re_wrap_fee: this.re_wrap_fee,
            shipping_fee: this.shipping_fee,
            path_file: this.imagePreviewUrls,
            packaging_fee: this.packaging_fee,
            other: this.other,
            weight: this.weight,
            width: this.width,
            height: this.height,
            long: this.long,
            charge_cue: this.CommasCue((this.width * this.height * this.long) / 1000000) ,
            product_amount: this.amount,
            charge_per_save: this.CommasUp(this.ThinkPerKilo),
            charge_per_kilo: this.CommasUp(this.charge_per_kilo),
            charge_per_cue: this.CommasUp(this.charge_per_cue),
            come_chinesewarehouse: this.come_chines,
            out_chinesewarehouse: this.out_chines,
            come_thaiwarehouse: this.come_thai,
            out_thaiwarehouse: this.out_thai,
            notproduct: this.notproduct,
            check_postnumber: true,
          }
          this.$http.post('/Tracking/store', obj)
            .then(response => {
              if (response.data.success) {
                this.Success(this.$t('key-285'))
              this.$router.push({ name: 'admin-tracking' })
              } else {
                this.SwalError(response.data.data)
              }
              
            })
            .catch(error => {
              console.log(error)
              
            })
        }
      })
    },
    async getUserData() {
      try {
        const { data: res } = await this.$http.get('/users/customer/active/lot')
        this.customerCode = res
        // console.log(this.customerCode)
      } catch (e) {
        console.log(e)
      }
    },
    Checkname() {
      if (this.$i18n.locale === 'th') {
       return 'pro_name' 
      } else if (this.$i18n.locale === 'en') {
        return 'pro_name_en'
      } else if (this.$i18n.locale === 'cn') {
        return 'pro_name_cn'
      }
    },
    async getProduct(data) {
      const params = {
        uid: data,
      }
      this.$http
        .get('/groupbyuser/show_user', { params })
        .then(response => {
          if (response.data.success) {
            const Res = response.data.data
            this.ProductTypeitem = []
            this.productType = null
            // eslint-disable-next-line no-restricted-syntax
            for (const items of Res.product_type.filter(item => item.product_type_class === this.transport)) {
              this.ProductTypeitem.push({
                pro_id: items.product_type_id, pro_name: items.product_type_name, pro_class: items.product_type_class, pro_kilo: items.product_type_kilo, pro_queue: items.product_type_queue,
                pro_initial: items.product_type_initial, pro_name_en: items.product_type_name_en,pro_name_cn: items.product_type_name_cn,
              })
            }
            this.ProductTypeitem.sort((a, b) => {
              if (a.pro_name < b.pro_name) {
                return -1
              } if (a.pro_name > b.pro_name) {
                return 1
              }
              return 0
            })
          }
        })
        .catch(error => console.log(error))
    },
    checkedytpe(item) {
      if (item) {
        // console.debug(item)
        this.transport = item
        this.getProduct(this.customer.id)
        this.productType = null
      }
    },
    reitem() {
      this.charge_per = 1
      this.items.charge_per_kilo = 0
      this.items.charge_per_cue = 0
      this.charge_percheck()
      this.checkedpricereq()
      this.checkedamount()
    },
    charge_percheck() {
      if (this.calPriceAuto) {
        if (this.weight) {
          const sum = this.weight * this.productType.pro_kilo * this.amount
          this.charge_per_kilo = sum
          this.ThinkPerKilo = this.CommasUp(this.charge_per_kilo)
          if (this.charge_per_kilo > this.charge_per_cue) {
            this.charge_per = 1
            this.charge_per_save = this.$t('calculateByKilo')
            this.ThinkPerKilo = this.CommasUp(this.charge_per_kilo)
          } else if (this.charge_per_cue > 0) {
            this.charge_per = 2
            this.charge_per_save = this.$t('key-98')
            this.ThinkPerKilo = this.CommasUp(this.charge_per_cue)
          }
        }
      }
    },
    checkedpricereq() {
      if (this.calPriceAuto) {
        if (this.width && this.height && this.long) {
          const req = this.CommasCue(((this.width * this.height * this.long) / 1000000)) * this.productType.pro_queue
          this.charge_per = 2
          this.ThinkPerKilo = this.CommasUp(req * this.amount)
          this.charge_per_cue = this.CommasUp(this.ThinkPerKilo)
          if (this.charge_per_cue > this.charge_per_kilo) {
            this.charge_per = 2
            this.charge_per_save = this.$t('key-98')
            this.ThinkPerKilo = this.CommasUp(this.charge_per_cue)
          } else if (this.charge_per_kilo > 0) {
            this.charge_per = 1
            this.charge_per_save = this.$t('calculateByKilo')
            this.ThinkPerKilo = this.CommasUp(this.charge_per_kilo)
          }
        }
      }
    },
    checkedamount(item) {
      if (this.calPriceAuto) {
        if (item) {
          if (this.charge_per === 1) {
            const sum = this.weight * this.productType.pro_kilo * this.amount
            this.ThinkPerKilo = sum
          }
          if (this.charge_per === 2) {
            const req = ((this.width * this.height * this.long) / 1000000) * this.productType.pro_queue
            this.ThinkPerKilo = req * this.amount
          }
        }
      }
    },
    PriceAuto() {
      if (this.calPriceAuto) {
        if (this.weight) {
          const sum = this.weight * this.productType.pro_kilo * this.amount
          this.charge_per_kilo = sum
          this.ThinkPerKilo = this.charge_per_kilo
          if (this.charge_per_kilo > this.charge_per_cue) {
            this.charge_per = 1
            this.charge_per_save = this.$t('calculateByKilo')
            this.ThinkPerKilo = this.charge_per_kilo
          } else if (this.charge_per_cue > 0) {
            this.charge_per = 2
            this.charge_per_save = this.$t('key-98')
            this.ThinkPerKilo = this.charge_per_cue
          }
        }
        if (this.width && this.height && this.long) {
          const req = ((this.width * this.height * this.long) / 1000000) * this.productType.pro_queue
          this.charge_per = 2
          this.ThinkPerKilo = req * this.amount
          if (this.charge_per_cue > this.charge_per_kilo) {
            this.charge_per = 2
            this.charge_per_save = this.$t('key-98')
            this.ThinkPerKilo = this.charge_per_cue
          } else if (this.charge_per_kilo > 0) {
            this.charge_per = 1
            this.charge_per_save = this.$t('calculateByKilo')
            this.ThinkPerKilo = this.charge_per_kilo
          }
        }
      }
    },
    handleFileChanges(event) {
      const { files } = event.target
      this.imagePreviewUrls = []
      // console.log(this.imagePreviewUrls)
      //   add to local storage
      localStorage.setItem(
        'imagePreviewUrls',
        JSON.stringify(this.imagePreviewUrls[0]),
      )

      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader()
        reader.onload = e => {
          this.imagePreviewUrls.push(e.target.result)
        }
        reader.readAsDataURL(files[i])
      }
    },
    removeImage(index) {
      const indexToRemove = this.imagePreviewUrls.indexOf(index)
      if (indexToRemove !== -1) {
        this.imagePreviewUrls.splice(indexToRemove, 1)
        // this.imagePreviewUrls = null
      }
    },
    addMoreImages() {
      this.$refs.fileInput.click()
      console.log(this.imagePreviewUrls)
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      this.imgBackground = file
      this.previewImage(file)
    },
    previewImage(file) {
      const reader = new FileReader()
      reader.onload = () => {
        this.imagePreviewUrl = reader.result
      }
      reader.readAsDataURL(file)
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-219')}!</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
